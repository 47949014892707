<template>
  <ion-page class="page bg-transparent mt-4 scrollable">
    <div class="wrapper pt-3">
      <!-- <router-link :to="{ name: 'raffles' }" class="clickable-item-hov d-flex align-items-center text-black"
          ><i class="ti-angle-left mr-1" />Back to all raffles</router-link
        > -->
      <router-link
        :to="
          route.params.id && raffle.slug
            ? { name: 'raffle-details', params: { slug: raffle.slug } }
            : { name: 'raffles' }
        "
      >
        <ion-button class="back-btn">
          <div class="d-flex">
            <ion-icon class="icon mr-2" :icon="arrowBackOutline" />
            <span> Go back</span>
          </div>
        </ion-button>
      </router-link>

      <div class="py-3 text-black">
        <div>
          <div class="title">Raffle/Giveaway Name*</div>
          <ion-input
            v-model="raffle.title"
            class="c-input one-input mt-2"
            placeholder="Name your raffle/giveaway something people will want to win!"
          />
        </div>
        <div class="mt-3 d-flex" style="font-weight: bold">
          <div @click="() => (changeColor = true)">
            <ion-checkbox
              :disabled="!(user.is_nsfw || user.show_nsfw)"
              v-model="raffle.is_nsfw"
              class="mr-1"
            ></ion-checkbox>
          </div>
          <ion-label
            style="font-size: 14px"
            :style="!(user.is_nsfw || user.show_nsfw) ? 'color: grey' : ''"
            class="label"
            >Mark raffle/giveaway as Mature
            <a
              @click.stop
              target="_blank"
              href="https://docs.google.com/document/d/1xSdAdkRj7n8BfJuz0KKPiM2IJTi8MiAVePruZqr4Gag/edit#heading=h.sqkkh44ifgqo"
              >(see content guidelines)</a
            ></ion-label
          >
        </div>
        <div
          v-if="!(user.is_nsfw || user.show_nsfw)"
          class="d-flex align-items-center p-2 my-2"
          style="height: 30px"
          :style="changeColor ? `background: #ffc409 !important` : ''"
        >
          <ion-icon class="mr-1" style="font-size: 12px !important" :icon="warningOutline" />
          <p style="font-size: 10px; color: grey" :style="changeColor ? `font-weight: bold` : ''">
            You must turn on “show mature content” and confirm you’re 18+ in
            <router-link :style="changeColor ? `font-weight: bold` : ''" to="/account/user-settings" target="_blank">
              <strong class="clickable-item-hov">Content Settings</strong>
            </router-link>
            to share mature content
          </p>
        </div>
        <div class="mt-3">
          <div class="title">Number of Winners*</div>
          <ion-input
            v-model="raffle.total_winners"
            type="number"
            class="c-input one-input mt-2"
            placeholder="Must be number. We'll use this to figure out how many winners to choose"
          />
        </div>
        <div class="mt-3">
          <div class="title">About this Raffle/Giveaway*</div>
          <div class="editor mt-2">
            <Editor
              v-model="raffle.description"
              :api-key="`${tinyMCEKey}`"
              :init="{
                height: 350,
                relative_urls: false,
                menu: {
                  insert: {
                    title: 'Insert',
                    items: 'image link media inserttable | charmap emoticons hr  | insertdatetime',
                  },
                },
                menubar: 'edit view insert format tools table help',
                plugins:
                  'quickbars advlist autolink lists link image charmap preview anchor searchreplace visualblocks code insertdatetime media table code help wordcount',
                toolbar:
                  'undo redo | bold italic backcolor |  blocks | fontfamily |  \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help',
                contextmenu: false,
                quickbars_insert_toolbar: false,
                quickbars_selection_toolbar: 'bold italic underline strikethrough link',
              }"
              placeholder="Dazzle us with details: Why is this giveaway the talk of the town?"
            />
          </div>
        </div>
        <div class="mt-3">
          <div class="title">Tags</div>
          <form @submit.prevent>
            <multiselect
              v-model="tags"
              :value="tags"
              tag-placeholder="Select to create a tag"
              placeholder="Press Enter to Add Tags ↩"
              select-label=""
              deselect-label=""
              :show-no-options="false"
              :options="tagList"
              :multiple="true"
              :taggable="true"
              :loading="areTagsLoading"
              open-direction="bottom"
              :close-on-select="false"
              class="tags mt-2"
              @tag="addTag"
              @search-change="doGetTagsDebounced"
            />
          </form>
        </div>
        <div class="mt-3">
          <div class="title">Terms and Conditions*</div>
          <div class="editor mt-2">
            <Editor
              v-model="raffle.terms_condition"
              :api-key="`${tinyMCEKey}`"
              :init="{
                height: 350,
                relative_urls: false,
                menu: {
                  insert: {
                    title: 'Insert',
                    items: 'image link media inserttable | charmap emoticons hr  | insertdatetime',
                  },
                },
                menubar: 'edit view insert format tools table help',
                plugins:
                  'quickbars advlist autolink lists link image charmap preview anchor searchreplace visualblocks code insertdatetime media table code help wordcount',
                toolbar:
                  'undo redo | bold italic backcolor |  blocks | fontfamily |  \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | help',
                contextmenu: false,
                quickbars_insert_toolbar: false,
                quickbars_selection_toolbar: 'bold italic underline strikethrough link',
              }"
              placeholder="Lay down the law: What are people allowed and not allowed to do? Commercial use? Does it need crediting? Do you retain the rights to the work or do they?"
            />
          </div>
        </div>
        <div class="mt-3">
          <div class="d-flex">
            <div class="title">Giveaway Images and Examples*</div>

            <div class="mx-2" style="margin-top: -3px">
              <UploadForm :is-multiple="true" style="width: 100px" @uploaded="addVisual" />
            </div>
          </div>
          <div class="visuals mt-2">
            <div class="content d-flex mt-2">
              <div v-for="(visual, index) of visuals" :key="index" class="visual mx-2 mt-2 position-relative">
                <img
                  loading="lazy"
                  class="visual visual-image clickable-item"
                  :src="visual"
                  @click="openVisualPreview(visual)"
                />
                <ion-button
                  color="transparent"
                  class="float-circle-btn clickable-item-hov"
                  @click.prevent="removeVisual(index)"
                >
                  <i class="ti-close" />
                </ion-button>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <div class="title">Let people earn bonus entries?</div>
          <div class="mt-3 d-flex align-items-center">
            <ion-checkbox v-model="raffle.making" class="mr-1"></ion-checkbox>
            <ion-label class="label">By making fan art of my characters</ion-label>
          </div>
          <div class="mt-3 d-flex align-items-center">
            <ion-checkbox v-model="raffle.sharing" class="mr-1"></ion-checkbox>
            <ion-label class="label">By sharing this giveaway on social media</ion-label>
          </div>
        </div>
        <div class="mt-3">
          <div class="title">Giveaway Duration*</div>
          <ion-select
            v-model="raffle.duration"
            mode="md"
            :class="{ hide: route.params.id }"
            placeholder="Select Duration."
            class="c-select featured-char-select bg-white mt-1"
            :multiple="false"
          >
            <ion-select-option v-for="(time, index) in timeDuration" :key="index" :value="time">
              {{ time }} days
            </ion-select-option>
          </ion-select>
        </div>
        <div class="mt-3">
          <ion-list class="bg-transparent">
            <ion-radio-group v-model="raffle.category" mode="md">
              <div class="title">Category*</div>
              <div class="d-flex flex-column" style="margin-left: -17px">
                <ion-item
                  v-for="category in raffleCategories"
                  :key="`raff-cat-${category.value}`"
                  lines="none"
                  class="bg-transparent"
                >
                  <ion-label>{{ category.name }}</ion-label>
                  <ion-radio slot="start" style="margin-inline-end: 12px" :value="category.value"></ion-radio>
                </ion-item>
              </div>
            </ion-radio-group>
            <ion-input
              v-if="raffle.category === 'other'"
              v-model="raffle.other_category"
              class="c-input mx-4"
              placeholder="Other - Specify the Category*"
            />
          </ion-list>
        </div>

        <div class="d-flex text-center justify-content-center py-3">
          <ion-icon class="info-icon mx-1" :icon="informationCircleOutline" /> We'll email you to connect with the
          winner(s). Submitting means you'll try your best to deliver once the raffle is over.
        </div>

        <div class="d-flex justify-content-center">
          <ion-button v-if="get(user, 'is_email_verified', true)" :disabled="!isFormComplete" @click="onSubmit"
            >Submit & Agree</ion-button
          >
          <ion-button v-else :disabled="!isFormComplete" @click="openVerificationModal">Submit & Agree</ion-button>
        </div>
      </div>
    </div>

    <visual-preview-modal
      :image-url="selectedVisualImg"
      :is-open="openVisualPreviewModal"
      @dismiss-modal="closeVisualPreviewModal"
    />

    <VerificationModal :is-open="verificationModal" @dismiss-modal="closeVerificationModal" />
  </ion-page>
</template>

<script lang="ts" setup>
import { arrowBackOutline, informationCircleOutline } from 'ionicons/icons';
import Multiselect from 'vue-multiselect';
import UploadForm from '@/shared/components/upload-form.vue';
import VisualPreviewModal from '@/shared/modals/VisualPreviewModal.vue';
import { getTags } from '@/shared/actions/tags';
import { warningOutline } from 'ionicons/icons';
import constants from '@/shared/statics/constants';
import { getRaffleById, postRaffle, updateRaffle } from '@/shared/actions/raffles';
import VerificationModal from '@/shared/modals/VerificationModal.vue';
import { authStore } from '@/shared/pinia-store/auth';
const { user } = authStore();

const raffle: any = ref({
  duration: null,
  making: true,
  sharing: true,
  is_nsfw: false,
  total_winners: 1,
});
const {
  public: { tinyMCEKey },
} = useRuntimeConfig();
const tagList = ref([] as any[]);
const router = useRouter();
const other_category = ref('');
const areTagsLoading = ref(false);
const selectedVisualImg = ref('');
const openVisualPreviewModal = ref(false);
const timeDuration = ref([3, 7, 14, 21, 30]);
const route = useRoute();
const raffleCategories = constants.raffleCategories;
const tags = computed({
  get() {
    return raffle?.value?.tags || [];
  },
  set(value) {
    raffle.value.tags = value;
  },
});
const addTag = (newTag: any) => {
  if (!newTag || !newTag.trim()) return;
  const toAdd = newTag.trim();
  const raffTags = raffle?.value?.tags || [];
  if (
    find(raffTags, (t) => {
      return t.toLowerCase() === toAdd.toLowerCase();
    })
  )
    return;
  raffTags.push(toAdd);
  raffle.value.tags = raffTags;
};

const verificationModal = ref(false);
const changeColor = ref(false);

const openVerificationModal = () => {
  verificationModal.value = true;
};

const closeVerificationModal = () => {
  verificationModal.value = false;
};

const isFormComplete = computed(() => {
  return (
    !isEmpty(raffle.value.title) &&
    !!raffle.value.total_winners &&
    !isEmpty(raffle.value.description) &&
    !isEmpty(raffle.value.terms_condition) &&
    !isEmpty(raffle.value.visuals) &&
    !!raffle.value.duration &&
    ((raffle.value.category === 'other' && !isEmpty(raffle.value.other_category)) ||
      (raffle.value.category !== 'other' && !isEmpty(raffle.value.category)))
  );
});

const onSubmit = async () => {
  if (!isFormComplete.value) return;
  const payload = {
    title: raffle.value.title,
    is_nsfw: raffle.value.is_nsfw,
    total_winners: raffle.value.total_winners,
    description: raffle.value.description,
    tags: raffle.value.tags,
    terms_condition: raffle.value.terms_condition,
    visuals: raffle.value.visuals,
    duration: raffle.value.duration,
    category: raffle.value.category,
    bonus_gateways: { fanwork: raffle.value.making, social_media_sharing: raffle.value.sharing },
    other_category: raffle.value.other_category,
  };

  const resp = route.params.id ? await updateRaffle(payload, route.params.id as string) : await postRaffle(payload);
  router.push({ name: 'raffle-details', params: { slug: resp.slug } });
};
const visuals = computed(() => {
  return raffle.value.visuals;
});

const addVisual = (images: string[]) => {
  if (!raffle.value.visuals) {
    raffle.value.visuals = [];
  }
  if (images?.length) {
    raffle.value.visuals = raffle.value.visuals.concat(images);
  }
};
const doGetTags = async (search?: string) => {
  areTagsLoading.value = true;

  if (!search) {
    tagList.value = [];
  } else {
    const resp = await getTags(1, 4, search);

    tagList.value = map(resp.results, (tag) => tag.name);
  }

  areTagsLoading.value = false;
};
const doGetTagsDebounced = debounce(doGetTags, 500);
const openVisualPreview = (imgUrl: string) => {
  selectedVisualImg.value = imgUrl;
  openVisualPreviewModal.value = true;
};

const closeVisualPreviewModal = () => {
  openVisualPreviewModal.value = false;
  selectedVisualImg.value = '';
};

const removeVisual = (index: number) => {
  raffle.value.visuals?.splice(index, 1);
};

onMounted(async () => {
  if (route.params.id) {
    const resp: any = await getRaffleById(route.params.id as string);
    raffle.value = resp[0];
    raffle.value.making = raffle.value.bonus_gateways.fanwork;
    raffle.value.sharing = get(raffle.value, 'bonus_gateways.social_media_sharing');
  }
});
</script>

<style lang="sass" scoped>
.info-icon
  font-size: 18px

.hide
  pointer-events: none
.float-circle-btn
  background-color: rgba(0,0,0,0.5)
  --border-radius: 100% !important
  border-radius: 100% !important
  width: 28px
  height: 28px
  --padding: 0
  --padding-start: 0
  --padding-end: 0
  right: 0
  top: 0
  position: absolute
  z-index: 1
.visuals
  .content
    flex-wrap: wrap
    margin: -12px

  .visual
    background-size: cover
    width: 110px
    height: 110px
    &:first-of-type
      margin-left: 7px !important
.visual-image
  object-fit: cover
.back-btn
  text-transform: none
  --border-radius: 15px
  --background: #214163
.page
  position: relative
  top: -20px
.title
  font-size: 20px
  font-weight: bold
  ion-item: part(native)
  padding-inline-start: 0px
ion-checkbox
  --checkbox-background-checked: #c3c3c3
  --border-color-checked: #c3c3c3
  --checkmark-color: black
</style>
